module.exports = [{
      plugin: require('C:/Users/msim/code/tbtfuture/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/msim/code/tbtfuture/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/msim/code/tbtfuture/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-70065106-24","cookieDomain":"tbtfuture.fyi"},
    },{
      plugin: require('C:/Users/msim/code/tbtfuture/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
